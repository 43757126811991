import React from "react"

import Logo from "../components/logo"

const Header = () => (
  <header style={{ marginBottom: `1.45rem` }}>
    <div>
      <h1 style={{
        width: 200,
        margin: 0,
      }}>
        <a
          href="https://abstractcre.com"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <Logo />
        </a>
      </h1>
    </div>
  </header>
)

Header.propTypes = {}

Header.defaultProps = {}

export default Header
