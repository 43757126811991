import React from "react";

const api = ({ type }) => (
  <rapi-doc
    id={type}
    spec-url={`https://api.abstractcre.com/${type}/swagger.json`}
    primary-color="#18B0F2"
    regular-font="'Source Sans Pro', sans-serif"
    mono-font="Roboto Mono"
    font-size="large"
    default-schema-tab="example"
    show-info={false}
    show-header={false}
    allow-try={false}
    allow-authentication={false}
    allow-server-selection={false}
    allow-api-list-style-selection={false}
  />
);

export default api;
