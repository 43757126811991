import React from "react"
import "fontsource-source-sans-pro";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Authentication from "../components/authentication"
import Api from "../components/api"

const IndexPage = () => (
  <Layout>
    <SEO title="API" />
    <Authentication />
    <Api type="abstracts" />
    <Api type="assets" />
    <Api type="documents" />
    <Api type="organizations" />
    <Api type="teams" />
    <Api type="templates" />
    <Api type="users" />
  </Layout>
)

export default IndexPage
