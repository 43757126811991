import React from "react"

const Authentication = () => (
  <section style={{ marginBottom: `1.45rem` }}>
    <figure
      style={{
        padding: 30,
        backgroundColor: '#FAFAFC',
        color: '#555',
      }}
    >
      <h2>Authentication</h2>
      <p style={{ marginBottom: 0 }}>
        The API token can be acquired from the user's Settings page.

        It must then be passed as an Authorization header in the format:{' '}
        <code>Authorization: Bearer [...]</code>
      </p>
    </figure>
    <p style={{marginTop: 30}}>
      Base route:{' '}
      <code>https://api.abstractcre.com</code>
    </p>
    <p style={{marginTop: 15}}>
      If you have any questions, please email us at:{' '}
      <code>support@abstractcre.com</code>
    </p>
  </section>
)

Authentication.propTypes = {}

Authentication.defaultProps = {}

export default Authentication
